* {
  font-family: "Nunito", sans-serif;
  box-sizing: border-box;
  --primary-color: #4e4376 !important;
  --secondary-color: #581845 !important;
}

.home-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  overflow: hidden;
}

.home-video {
  min-height: 100%;
  min-width: 100%;
}

.home-background-overlay {
  background: linear-gradient(90deg, var(--secondary-color) 0%, var(--primary-color) 100%);
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.home-row {
  height: 100% !important;
}

@media (max-width: 767px) {
  .Dashboard-container {
    height: auto !important;
  }

  .home-row {
      height: auto !important;
  }
}