.button-link {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    min-height: 50px;
    border-radius: 15px;
    cursor: pointer;
    transition: 0.4s;
    background-color: var(--primary-color);
    border: 2px solid var(--primary-color);
    padding: 0 30px;
    text-decoration: none;
    margin: 0;
    width: 100%;
}

.button-link:hover {
    opacity: 0.7;
}

.button-link:active {
    opacity: 0.5;
}

.button-link:focus {
    outline: none;
}

.button-link > span {
    margin: auto 10px;
    color: var(--white);
    font-size: 12px;
    font-weight: bold;
}

.button-link-icon {
    color: var(--white);
    font-size: 20px;
    width: 30px;
}