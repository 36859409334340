.card {
    position: relative;
    background-color: rgba(255, 255, 255, 0.7);
    color: var(--black);
    border-radius: 10px;
    width: 80%;
    margin: auto;
    padding: 20px;
}

.card-top-border {
    position: absolute;
    background-color: var(--primary-color);
    height: 0.25rem;
    width: 100px;
    top: 0;
}

.card-title {
    margin: 0;
    margin-bottom: 30px;
    font-size: 18px;
    font-weight: bold;
}

@media (max-width: 767px) {
    .card {
        width: 100%;
    }
}
